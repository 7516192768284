export enum TutorialElementId {
  ADD_FILTER_BUTTON = "addFilterButton",
  DAY_DROPDOWN = "dayDropdown",
  DAY_FILTER = "dayFilter",
  DAY_FILTER_PREVIEW_1 = "dayFilterPreview1",
  EVENT_FILTER = "eventFilter",
  FILTERS_BAR = "filtersBar",
  FILTERS_PREVIEW = "filterPreview",
  FILTER_DROPDOWN_FOOTER = "filterDropdownFooter",
  FILTER_DROPDOWN_INPUT = "filterDropdownInput",
  GRAPH_WIDGET = "graphWidget",
  HIGHEST_ZONE_INPUT = "highestZoneInput",
  INSTALLATIONS_TABLE = "installationsTable",
  KPI_CARD = "kpiCard",
  KPI_TOOLTIP_ICON = "kpiTooltipIcon",
  KPI_WIDGET = "kpiWidget",
  SIDEBAR_COUNTING_LABEL = "sidebarCountingLabel",
  TIMESPAN_FILTER = "timespanFilter",
  TIMESPAN_FILTER_PREVIEW_1 = "timespanFilterPreview1",
  TIMESPAN_FILTER_PREVIEW_2 = "timespanFilterPreview2",
  WEATHER_WIDGET = "witherWidget",
  ZONE_FILTER = "zoneFilter",
  ZONE_FILTER_PREVIEW_1 = "zoneFilterPreview1",
  ZONE_FILTER_PREVIEW_2 = "zoneFilterPreview2",
  ZONE_OPTION = "zoneOption",
}
