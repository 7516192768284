import { patternFormatter } from "react-number-format";

import { PLACEHOLDER_NUMBER } from "@common/constants";
import { type PhoneCountry } from "@common/interfaces";

import availableCountries from "../availableCountries.json";

const getLargestPhoneMask = (countryMasks: string[]): string =>
  countryMasks.reduce((previous, current) => (previous.length > current.length ? previous : current));

export const countriesPhones = availableCountries.map((country): PhoneCountry => {
  const countryPhoneMask = typeof country.mask === "object" ? getLargestPhoneMask(country.mask) : country.mask;

  return {
    id: country.iso,
    countryName: country.name,
    countryCode: country.code,
    icon: country.flag,
    mask: country.mask,
    inputMask: countryPhoneMask,
    placeholder: patternFormatter(PLACEHOLDER_NUMBER, {
      format: countryPhoneMask,
    }),
  };
});
