import { UserLanguage } from "@technis/shared";
import { createElement } from "react";

import FranceFlag from "../../../assets/colorfulIcons/france-flag.svg";

export const languages = [
  {
    caption: "France",
    value: UserLanguage.FR,
    icon: createElement(FranceFlag),
  },
];
