/* eslint-disable i18next/no-literal-string */
import i18next from "i18next";
import React from "react";

import { translation } from "@lang/translation";

import kpiStyles from "../../../../styles/kpi.module.scss";

export const dwellFormatter = (hours: number, minutes: number, seconds: number): JSX.Element =>
  hours || minutes || seconds ? (
    <>
      {!!hours && (
        <>
          <span>{hours}</span>
          <span> h </span>
        </>
      )}
      {!!minutes && (
        <>
          <span>{minutes}</span>
          <span> m </span>
        </>
      )}
      {!!seconds && (
        <>
          <span>{seconds.toFixed(0)}</span>
          <span> s</span>
        </>
      )}
    </>
  ) : (
    <>0</>
  );

export const binaryOccupancyFormatter = (key: string): JSX.Element => (
  <>
    <span>{i18next.t(key)}</span>
    <span className={kpiStyles.kpiValue}></span>
  </>
);

export const comfortScoreFormatter = (valueAsString: string): string =>
  i18next.t(translation.common.scores.COMFORT_SCORE[valueAsString as unknown as keyof typeof translation.common.scores.COMFORT_SCORE], {
    defaultValue: "-",
  });
export const presureScoreFormatter = (valueAsString: string): string =>
  i18next.t(translation.common.scores.GENERAL_SCORE[valueAsString as unknown as keyof typeof translation.common.scores.GENERAL_SCORE], {
    defaultValue: "-",
  });
