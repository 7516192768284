import { type TFunction } from "i18next";
import moment from "moment";

import { TimeRange } from "@common/enums/period";
import { type TimespanOption } from "@common/interfaces/filters";
import { type AvailableTimeRange } from "@common/types/availableTimeRange/availableTimeRange";

import { translation } from "@lang/translation";

export const getTimespanOptions = ({
  timespan,
  minDate,
  translate,
}: {
  timespan?: AvailableTimeRange;
  minDate?: number;
  translate: TFunction;
}): TimespanOption[] => {
  const dateNow = moment().valueOf();
  const yesterday = moment().subtract(1, "d").valueOf();
  const weekAgo = moment().subtract(6, "d").valueOf();
  const twoWeeksAgo = moment().subtract(13, "d").valueOf();
  const monthAgo = moment().subtract(1, "months").valueOf();
  const quarterAgo = moment().subtract(1, "quarter").valueOf();

  const timespanRanges = {
    [TimeRange.TODAY]: {
      dateEnd: dateNow,
      dateBegin: dateNow,
      caption: translate(translation.filters.timespan.today),
      id: TimeRange.TODAY,
    },
    [TimeRange.YESTERDAY]: {
      dateEnd: yesterday,
      dateBegin: yesterday,
      caption: translate(translation.filters.timespan.yesterday),
      id: TimeRange.YESTERDAY,
    },
    [TimeRange.LAST_WEEK]: {
      dateEnd: dateNow,
      dateBegin: weekAgo,
      caption: translate(translation.filters.timespan.lastWeek),
      id: TimeRange.LAST_WEEK,
    },
    [TimeRange.LAST_2_WEEKS]: {
      dateEnd: dateNow,
      dateBegin: twoWeeksAgo,
      caption: translate(translation.filters.timespan.last2Weeks),
      id: TimeRange.LAST_2_WEEKS,
    },
    [TimeRange.LAST_MONTH]: {
      dateEnd: dateNow,
      dateBegin: monthAgo,
      caption: translate(translation.filters.timespan.lastMonth),
      id: TimeRange.LAST_MONTH,
    },
    [TimeRange.LAST_QUARTER]: {
      dateEnd: dateNow,
      dateBegin: quarterAgo,
      caption: translate(translation.filters.timespan.lastQuarter),
      id: TimeRange.LAST_QUARTER,
    },
  };

  if (timespan) {
    return [timespanRanges[timespan]];
  }

  return Object.values(timespanRanges).filter((item) => !minDate || item.dateBegin >= minDate);
};
