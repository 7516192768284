export enum BreadcrumbRouteName {
  CUSTOM_PAGE = "CUSTOM_PAGE",
  EXPORT_DATA = "EXPORT_DATA",
  HELP_CENTER = "HELP_CENTER",
  HOME = "HOME",
  INSTALLATION__AIR_QUALITY = "INSTALLATION_AIR_QUALITY",
  INSTALLATION__AIR_QUALITY__DETAILS = "INSTALLATION_AIR_QUALITY_DETAILS",
  INSTALLATION__COUNTING = "INSTALLATION_COUNTING",
  INSTALLATION__COUNTING__DETAILS = "INSTALLATION_COUNTING_DETAILS",
  SETTINGS = "SETTINGS",
  SETTINGS__ALERTS = "SETTINGS_ALERTS",
  SETTINGS__ALERTS__NEW = "SETTINGS_ALERTS_NEW",
  SETTINGS__ALERTS__UPDATE = "SETTINGS_ALERTS_UPDATE",
  USER_PROFILE = "USER_PROFILE",
  USER_PROFILE_GENERAL = "USER_PROFILE_GENERAL",
  USER_PROFILE_PREFERENCES = "USER_PROFILE_PREFERENCES",
  USER_PROFILE_SECURITY = "USER_PROFILE_SECURITY",
  SETTINGS_USER_MANAGEMENT = "SETTINGS_USER_MANAGEMENT",
  SETTINGS_USER_MANAGEMENT_PROFILE = "SETTINGS_USER_MANAGEMENT_PROFILE",
  SETTINGS_ORGANIZATION = "SETTINGS_ALERTS_UPDATE",
}
