import { type TFunction } from "i18next";

import { translation } from "@lang/translation";

interface Arguments {
  fallback?: string;
  firstName?: string;
  lastName?: string;
  t: TFunction;
}

export const getFullName = ({ firstName = "", lastName = "", fallback, t }: Arguments): string => {
  if (!lastName && !firstName) {
    if (fallback) {
      return fallback;
    }

    return t(translation.common.noUserName);
  }

  return `${firstName} ${lastName}`.trim();
};
