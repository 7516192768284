import { type OtpContactType, type TokenType, type User } from "@technis/shared";
import jwtDecode, { type JwtPayload } from "jwt-decode";

interface Token extends JwtPayload {
  claims: {
    adminUserId?: User["id"];
    otpContact?: OtpContactType;
    tokenType: TokenType;
  };
  uid: User["id"];
}

export const getTokenData = (token: string): Token => jwtDecode(token);

export const isTokenExpired = (token: string): boolean => {
  const { exp = 0 } = getTokenData(token);

  return exp === 0 || exp * 1000 < Date.now();
};
