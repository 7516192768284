import {
  BLACK,
  DARK_BLUE,
  type Genders,
  GRAY_30,
  GRAY_80,
  HEX_OPACITY,
  LIGHTER_GRAY,
  RetailMetrics,
  type ThemeConfiguration,
  ThemeType,
  WHITE,
} from "@technis/shared";
import { ComponentSize, IconName, type Theme, ThemeContextProvider as UIThemeContextProvider } from "@technis/ui";
import React, { createContext, type FC, useContext, useEffect, useState } from "react";

import { type AppThemeType } from "@common/types/theme/theme";

// const ELECTRIC_VIOLET = "#4C00EE";
const PURPLE = "#8F4DEA";
const WHITE_LILAC = "#F5F7FB";
const DEEP_LILAC = "#7E57C2";
const DARK_VIOLET = "#363670";
const COLD_PURPLE = "#B39DDB";

export const KpiColors: Record<RetailMetrics, string> = {
  [RetailMetrics.DWELL]: "#978AFF",
  [RetailMetrics.TEMPERATURE]: "#FFAB8A",
  [RetailMetrics.HUMIDITY_LEVEL]: "#978AFF",
  [RetailMetrics.CO2_LEVEL]: "#FFD28A",
  [RetailMetrics.CAPTURE_RATE]: "#CEC8FD",
  [RetailMetrics.POPULARITY]: "#FFAB8A",
  [RetailMetrics.AFFLUENCE_IN]: "#FFAB8A",
  [RetailMetrics.AFFLUENCE_OUT]: "#5abfd7",
  [RetailMetrics.ABANDON_RATE_QUEUE]: "#f5a623",
  [RetailMetrics.AFFLUENCE_IN_QUEUE]: "#f5a623",
  [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "#f5a623",
  [RetailMetrics.DWELL_QUEUE]: "#f5a623",
  [RetailMetrics.GROUP_SIZE_IN]: "#f5a623",
  [RetailMetrics.GROUP_SIZE_OUT]: "#f5a623",
  [RetailMetrics.INSIDE]: "#FFD28A",
  [RetailMetrics.INSIDE_QUEUE]: "#f5a623",
  [RetailMetrics.N_GROUPS_IN]: "#c95be8",
  [RetailMetrics.N_GROUPS_OUT]: "#f5a623",
  [RetailMetrics.OCCUPANCY]: "#4ea7f9",
  [RetailMetrics.GROUP_PERCENTAGE]: "#ffd28a",
  [RetailMetrics.OCCUPANCY_STATUS]: "#f5a623",
  [RetailMetrics.DEVICE_STATUS]: "#f5a623",
  [RetailMetrics.DENSITY]: "#70df9e",
  [RetailMetrics.REVERSE_DENSITY]: "#f5a623",
  [RetailMetrics.LIMIT]: "#f5a623",
  [RetailMetrics.WAITING_TIME]: "#f5a623",
  [RetailMetrics.BINARY_OCCUPANCY]: "#f5a623",
  [RetailMetrics.CO2_LEVEL_SCORE]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: "#f5a623",
  [RetailMetrics.COMFORT_SCORE]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: "#f5a623",
  [RetailMetrics.HUMIDITY_LEVEL_SCORE]: "#f5a623",
  [RetailMetrics.LUMINOSITY]: "#f5a623",
  [RetailMetrics.LUMINOSITY_SCORE]: "#f5a623",
  [RetailMetrics.PM10_LEVEL]: "#f5a623",
  [RetailMetrics.PM10_LEVEL_SCORE]: "#f5a623",
  [RetailMetrics.PM2_5_LEVEL]: "#f5a623",
  [RetailMetrics.PM2_5_LEVEL_SCORE]: "#f5a623",
  [RetailMetrics.PRESSURE]: "#f5a623",
  [RetailMetrics.PRESSURE_SCORE]: "#f5a623",
  [RetailMetrics.TEMPERATURE_SCORE]: "#f5a623",
  [RetailMetrics.TVOC]: "#f5a623",
  [RetailMetrics.TVOC_SCORE]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: "#f5a623",
  [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: "#f5a623",
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: "#f5a623",
  [RetailMetrics.NUMBER_OF_REFUSAL]: "#f5a623",
  [RetailMetrics.REFUSAL_RATE]: "#f5a623",
};

export const kpiIcons: Record<RetailMetrics, IconName> = {
  [RetailMetrics.DWELL]: IconName.DWELL_BASIC,
  [RetailMetrics.CAPTURE_RATE]: IconName.CAPTURE_RATE,
  [RetailMetrics.POPULARITY]: IconName.POPULARITY,
  [RetailMetrics.AFFLUENCE_IN]: IconName.AFFLUENCE_IN,
  [RetailMetrics.AFFLUENCE_OUT]: IconName.AFFLUENCE_OUT,
  [RetailMetrics.HUMIDITY_LEVEL]: IconName.DROPLET,
  [RetailMetrics.TEMPERATURE]: IconName.TEMPERATURE_HALF,
  [RetailMetrics.CO2_LEVEL]: IconName.CO2_LEVEL,
  [RetailMetrics.INSIDE]: IconName.INSIDE,
  [RetailMetrics.N_GROUPS_IN]: IconName.N_GROUPS_IN,
  [RetailMetrics.DENSITY]: IconName.USER_GROUP,
  [RetailMetrics.OCCUPANCY]: IconName.USER_GROUP,
  [RetailMetrics.GROUP_PERCENTAGE]: IconName.GROUP_SIZE_PERCENTAGE,
  [RetailMetrics.COMFORT_SCORE]: IconName.COMFORT_SCORE,
  [RetailMetrics.ABANDON_RATE_QUEUE]: IconName.ABANDON_RATE_QUEUE,
  [RetailMetrics.AFFLUENCE_IN_QUEUE]: IconName.AFFLUENCE_IN_QUEUE,
  [RetailMetrics.AFFLUENCE_OUT_QUEUE]: IconName.HEART,
  [RetailMetrics.BINARY_OCCUPANCY]: IconName.BINARY_OCCUPANCY,
  [RetailMetrics.CO2_LEVEL_SCORE]: IconName.CO2_LEVEL,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION]: IconName.FUEL_CONSUMPTION,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ]: IconName.FUEL_CONSUMPTION_CO2_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.FUEL_CONSUMPTION_CO2_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ]: IconName.FUEL_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.FUEL_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.COMBUSTIBLE_CONSUMPTION_PER_SQM]: IconName.FUEL_CONSUMPTION,
  [RetailMetrics.DEVICE_STATUS]: IconName.HEART,
  [RetailMetrics.DWELL_QUEUE]: IconName.DWELL_QUEUE,
  [RetailMetrics.ELECTRICITY_CONSUMPTION]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_DAY]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_ON_INTERVAL]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_CONSUMPTION_PER_SQM]: IconName.ELECTRICITY_CONSUMPTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_DAY]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_CO2_EQ_PER_SQM]: IconName.ELECTRICITY_PRODUCTION_CO2_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_DAY]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ENERGY_EQ_PER_SQM]: IconName.ELECTRICITY_PRODUCTION_ENERGY_EQ,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_DAY]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_ON_INTERVAL]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.ELECTRICITY_PRODUCTION_PER_SQM]: IconName.ELECTRICITY_PRODUCTION,
  [RetailMetrics.GROUP_SIZE_IN]: IconName.GROUP_SIZE_IN,
  [RetailMetrics.GROUP_SIZE_OUT]: IconName.GROUP_SIZE_OUT,
  [RetailMetrics.HUMIDITY_LEVEL_SCORE]: IconName.HUMIDITY_LEVEL,
  [RetailMetrics.INSIDE_QUEUE]: IconName.INSIDE_QUEUE,
  [RetailMetrics.LIMIT]: IconName.HEART,
  [RetailMetrics.LUMINOSITY]: IconName.LUMINOSITY,
  [RetailMetrics.LUMINOSITY_SCORE]: IconName.LUMINOSITY,
  [RetailMetrics.N_GROUPS_OUT]: IconName.N_GROUPS_OUT,
  [RetailMetrics.OCCUPANCY_STATUS]: IconName.HEART,
  [RetailMetrics.PM10_LEVEL]: IconName.PM10_LEVEL,
  [RetailMetrics.PM10_LEVEL_SCORE]: IconName.PM10_LEVEL,
  [RetailMetrics.PM2_5_LEVEL]: IconName.PM2_5_LEVEL,
  [RetailMetrics.PM2_5_LEVEL_SCORE]: IconName.PM2_5_LEVEL,
  [RetailMetrics.PRESSURE]: IconName.PRESSURE,
  [RetailMetrics.PRESSURE_SCORE]: IconName.PRESSURE,
  [RetailMetrics.REVERSE_DENSITY]: IconName.HEART,
  [RetailMetrics.TEMPERATURE_SCORE]: IconName.TEMPERATURE,
  [RetailMetrics.TVOC]: IconName.TVOC,
  [RetailMetrics.TVOC_SCORE]: IconName.TVOC,
  [RetailMetrics.WAITING_TIME]: IconName.WAITING_TIME,
  [RetailMetrics.WATER_CONSUMPTION]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_PER_SQM]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_PER_SQM]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_PER_SQM]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_DAY]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_CO2_EQ_ON_INTERVAL]: IconName.WATER_CONSUMPTION_CO2_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_DAY]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ENERGY_EQ_ON_INTERVAL]: IconName.WATER_CONSUMPTION_ENERGY_EQ,
  [RetailMetrics.WATER_CONSUMPTION_ON_DAY]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.WATER_CONSUMPTION_ON_INTERVAL]: IconName.WATER_CONSUMPTION,
  [RetailMetrics.NUMBER_OF_REFUSAL]: IconName.HEART,
  [RetailMetrics.REFUSAL_RATE]: IconName.HEART,
};

export type AppTheme = Theme & {
  genderColors: {
    [key in Genders]: string;
  };
  kpiColors: {
    [key in RetailMetrics]: string;
  };
};

const getAppTheme = (themeConfiguration?: ThemeConfiguration): AppThemeType => {
  const highlightColor = themeConfiguration?.highlightColor || COLD_PURPLE;
  const highlightColor5 = highlightColor + HEX_OPACITY["5"];
  const highlightColor15 = highlightColor + HEX_OPACITY["15"];
  const highlightColor45 = highlightColor + HEX_OPACITY["45"];
  const lightTheme = {
    base: {
      colors: {
        primary: themeConfiguration?.primaryColor || DARK_VIOLET,
        secondary: themeConfiguration?.secondaryColor || DEEP_LILAC,
        background: themeConfiguration?.backgroundColor || WHITE_LILAC,
        light: WHITE,
        dark: BLACK,
        border: GRAY_30,
        hover: highlightColor5,
        shadow: highlightColor15,
      },
      font: {
        color: themeConfiguration?.fontColor || GRAY_80,
      },
    },
    components: {
      heading: {
        levels: {
          [ComponentSize.MEDIUM]: 20,
        },
      },
      input: {
        border: themeConfiguration?.primaryColor || DARK_VIOLET,
      },
      topBar: {
        shadowColor: highlightColor15,
      },
      tabBar: {
        borderColor: highlightColor45,
        tabHoverColor: highlightColor15,
      },
      widget: {
        boxShadow: `0 0 1px 0 ${LIGHTER_GRAY}`,
      },
      table: {
        border: highlightColor15,
        headerBackgroundColor: highlightColor15,
        headerColor: themeConfiguration?.primaryColor || DARK_VIOLET,
        headerFontSize: 20,
        hover: highlightColor15,
        activeColor: themeConfiguration?.secondaryColor || DEEP_LILAC,
      },
      pieChart: {
        colors: [],
      },
      charts: {
        bar: {
          columnColor: themeConfiguration?.charts?.columnColors || [highlightColor],
          seriesColors: themeConfiguration?.charts?.seriesColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
          stackedColors: themeConfiguration?.charts?.stackedColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
        },
        fontColor: themeConfiguration?.primaryColor || DARK_VIOLET,
        gridMap: {
          titleColor: DARK_BLUE,
          plotBorderWidth: 0,
          borderColor: WHITE,
          borderWidth: 2,
          minColor: WHITE,
        },
        circle: {
          seriesColors: themeConfiguration?.charts?.seriesColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
        },
      },
      actionItem: {
        hoverColor: highlightColor45,
      },
      dropDown: {
        backgroundColor: highlightColor45,
      },
      dropdownMenu: {
        backgroundColor: WHITE,
      },
    },
    kpiColors: themeConfiguration?.kpiColors || KpiColors,
    genderColors: themeConfiguration?.genderColors,
  };

  return {
    [ThemeType.LIGHT]: lightTheme,
    [ThemeType.DARK]: lightTheme,
  };
};

export const AppThemeContext = createContext({ theme: {} as AppTheme });

export const useAppTheme = (): { theme: AppTheme } => useContext(AppThemeContext);

type AppThemeProps = {
  children?: JSX.Element;
  setRoot: boolean;
  setThemeVariant?: (variant: ThemeType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
};

const AppTheme: FC<AppThemeProps> = ({ children, setRoot, setThemeVariant, theme }): JSX.Element => {
  const [themeType] = useState(ThemeType.LIGHT);

  useEffect(() => {
    if (themeType && setRoot) {
      setThemeVariant && setThemeVariant(themeType);
      document.documentElement.className = themeType;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeType]);

  const currentTheme: AppTheme = theme[themeType];
  return <AppThemeContext.Provider value={{ theme: currentTheme }}>{children}</AppThemeContext.Provider>;
};

type ThemeContextProviderProps = {
  children: JSX.Element;
  setRoot: boolean;
  themeConfiguration?: ThemeConfiguration;
};

export const ThemeContextProvider: FC<ThemeContextProviderProps> = (props) => {
  const theme = getAppTheme(props.themeConfiguration);

  return (
    <UIThemeContextProvider defaultThemeVariant={ThemeType.LIGHT} theme={theme}>
      <AppTheme theme={theme} {...props} />
    </UIThemeContextProvider>
  );
};
