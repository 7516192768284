import { Granularities } from "@technis/shared";
import { type TFunction } from "i18next";

import { translation } from "@lang/translation";

interface Arguments {
  t: TFunction;
}

type GranularityOptionKey =
  | Granularities.MINUTE
  | Granularities.MINUTES_5
  | Granularities.MINUTES_10
  | Granularities.MINUTES_15
  | Granularities.MINUTES_30
  | Granularities.HOUR
  | Granularities.DAY
  | Granularities.WEEK
  | Granularities.MONTH;

type GranularityOptions = {
  [key in GranularityOptionKey]: { caption: string; id: Granularities; value: number };
};

export const getGranularityOptions = ({ t }: Arguments): GranularityOptions => {
  const { min, hour, month, day, week } = translation.exportData.granularity;

  return {
    [Granularities.MINUTE]: { id: Granularities.MINUTE, caption: "1 " + t(min), value: 60 },
    [Granularities.MINUTES_5]: { id: Granularities.MINUTES_5, caption: "5 " + t(min), value: 300 },
    [Granularities.MINUTES_10]: { id: Granularities.MINUTES_10, caption: "10 " + t(min), value: 600 },
    [Granularities.MINUTES_15]: { id: Granularities.MINUTES_15, caption: "15 " + t(min), value: 900 },
    [Granularities.MINUTES_30]: { id: Granularities.MINUTES_30, caption: "30 " + t(min), value: 1800 },
    [Granularities.HOUR]: { id: Granularities.HOUR, caption: t(hour), value: 3600 },
    [Granularities.DAY]: { id: Granularities.DAY, caption: t(day), value: 86_400 },
    [Granularities.WEEK]: { id: Granularities.WEEK, caption: t(week), value: 604_800 },
    [Granularities.MONTH]: { id: Granularities.MONTH, caption: t(month), value: 2_592_000 },
  };
};
