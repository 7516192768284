import { OnboardingComponentName } from "@common/enums";

import { AboutYou, CreatePassword, OrganizationSettings, ProfileSettings, Security } from "@components/onboarding/onboardingSteps";

export const onboardingComponents = {
  [OnboardingComponentName.ORGANIZATION_SETTINGS]: OrganizationSettings,
  [OnboardingComponentName.ABOUT_YOU]: AboutYou,
  [OnboardingComponentName.CREATE_PASSWORD]: CreatePassword,
  [OnboardingComponentName.PROFILE_SETTINGS]: ProfileSettings,
  [OnboardingComponentName.SECURITY]: Security,
};
