import { type Profile } from "@technis/shared/dist/types/profile.type";
import { isBoolean } from "lodash";

import { UserProfileMode } from "@common/enums/userProfile/userProfile";

type ProfileRights = Pick<Profile, "rights">;

interface UserManagementPermissions {
  (profile?: ProfileRights): {
    isUserManagementAvailable?: boolean;
    isUserManagementDeletable: boolean;
    isUserManagementEditable: boolean;
    isUserManagementRouteActive: boolean;
    userManagementMode: UserProfileMode.PARTIALLY_EDITABLE | UserProfileMode.READ_ONLY;
  };
}

export const parseUserManagementPermissions: UserManagementPermissions = (profileRights?: ProfileRights) => {
  const isUserManagementAvailable = profileRights?.rights.organization.user.read;
  const isUserManagementEditable = Boolean(profileRights?.rights.organization.user.edit);
  const isUserManagementDeletable = Boolean(profileRights?.rights.organization.user.delete);
  const isUserManagementRouteActive = isUserManagementAvailable || !isBoolean(isUserManagementAvailable);
  const userManagementMode = isUserManagementEditable ? UserProfileMode.PARTIALLY_EDITABLE : UserProfileMode.READ_ONLY;

  return {
    isUserManagementEditable,
    isUserManagementRouteActive,
    userManagementMode,
    isUserManagementDeletable,
    isUserManagementAvailable,
  };
};
