import { ActionType } from "@technis/shared";

import { defaultContacts } from "@common/constants/alert/defaultContacts";
import { type AlertNotification, type AlertNotificationContacts } from "@common/interfaces/alert";

interface Planning {
  mails: string[];
  phones: string[];
  userIds: string[];
}

interface MapNotificationsToPlanningArguments {
  contacts?: AlertNotificationContacts;
  notifications: AlertNotification[];
}

export const mapNotificationsToPlanning = ({ notifications, contacts = defaultContacts }: MapNotificationsToPlanningArguments): Planning =>
  notifications.reduce<Planning>(
    (accumulator, notification, currentIndex) => {
      if (notification.type === ActionType.EMAIL) {
        accumulator.mails = contacts[ActionType.EMAIL].map(({ email }) => String(email));
      }

      if (notification.type === ActionType.SMS) {
        accumulator.phones = contacts[ActionType.SMS].map(({ phone }) => String(phone));
      }

      if (currentIndex === 0) {
        accumulator.userIds = notification.recipients.map((recipient) => String(recipient.id));
      }

      return accumulator;
    },
    { mails: [], phones: [], userIds: [] },
  );
