import { UserLanguage } from "@technis/shared";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { type RootState } from "@store/rootReducer";

import availableCountries from "../configs/availableCountries.json";

const DEFAULT_LANGUAGE = UserLanguage.EN;

const COUNTRY_CODE_BY_LANGUAGE: { [key in UserLanguage]: string } = {
  [UserLanguage.EN]: "US",
  [UserLanguage.FR]: "FR",
  [UserLanguage.IT]: "IT",
  [UserLanguage.ES]: "ES",
  [UserLanguage.DE]: "DE",
  [UserLanguage.AR]: "AR",
};
const DEFAULT_COUNTRY_CODE = COUNTRY_CODE_BY_LANGUAGE[UserLanguage.EN];

const getLanguageFromBrowserValue = (navigatorValue: string): UserLanguage => navigatorValue.split("-")[0] as UserLanguage;
const getCountryCodeFromBrowserValue = (navigatorValue: string): string => navigatorValue.split("-")[1];
const isCountryCodeValid = (countryCode: string): boolean =>
  availableCountries.some((availableCountry) => availableCountry.iso === countryCode);

const getCurrentLanguage = (userLanguage?: UserLanguage): UserLanguage => {
  const browserLanguage = getLanguageFromBrowserValue(window.navigator.language);

  if (userLanguage) return userLanguage;
  if (browserLanguage && Object.values(UserLanguage).includes(browserLanguage)) return browserLanguage;
  return DEFAULT_LANGUAGE;
};

const getCountryCode = (language: UserLanguage): string => {
  const browserLanguage = getLanguageFromBrowserValue(window.navigator.language);
  const browserCountryCode = getCountryCodeFromBrowserValue(window.navigator.language);

  if (isCountryCodeValid(browserCountryCode)) {
    if (browserLanguage === language) {
      return browserCountryCode;
    }
    // Fallback on userLanguage
    if (Object.keys(COUNTRY_CODE_BY_LANGUAGE).includes(language)) return COUNTRY_CODE_BY_LANGUAGE[language];
  }
  return DEFAULT_COUNTRY_CODE;
};

export interface UseLanguageResult {
  lang: UserLanguage;
  countryCode: string;
}

export const useLanguage = (): UseLanguageResult => {
  const [lang, setLang] = useState<UserLanguage>(DEFAULT_LANGUAGE);
  const [countryCode, setCountryCode] = useState<string>(DEFAULT_COUNTRY_CODE);

  const { lang: userLanguage } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage(userLanguage);

    setLang(currentLanguage);
    setCountryCode(getCountryCode(currentLanguage));
  }, [userLanguage]);

  return {
    lang,
    countryCode,
  };
};
