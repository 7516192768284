import { useEffect, useRef, useState } from "react";

interface Props {
  onExpired?: () => void;
  seconds?: number;
}

interface UseTimer {
  (props?: Props): {
    isFinished: boolean;
    resetTimer: () => void;
    start: () => void;
    timer: number;
  };
}

const useInterval = (callback: () => void, delay: number | null): NodeJS.Timer | null => {
  const savedCallback = useRef<() => void>();
  const timerId = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => {
      savedCallback.current && savedCallback.current();
    };

    if (delay !== null) {
      timerId.current = setInterval(tick, delay);
      return (): void => {
        if (timerId.current) {
          clearInterval(timerId.current);
        }
      };
    }
  }, [delay]);
  return timerId.current;
};

export const useTimer: UseTimer = (props) => {
  const seconds = props?.seconds ?? 60;
  const onExpired =
    props?.onExpired ??
    function (): void {
      return;
    };

  const [timer, setTimer] = useState(0);
  const isFinished = timer === 0;
  const delay = isFinished ? null : 1000;

  const timerId = useInterval(() => setTimer((timer) => timer - 1), delay);

  const handleStart = (): void => {
    setTimer(seconds);
  };

  const resetTimer = (): void => {
    if (timerId) {
      clearInterval(timerId);
      setTimer(0);
    }
  };

  if (isFinished) {
    onExpired();
  }

  return {
    isFinished,
    timer,
    start: handleStart,
    resetTimer,
  };
};
