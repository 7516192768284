export enum AlertDetail {
  CONDITION = "CONDITION",
  EVENT = "EVENT",
  ID = "ID",
  INSTALLATION_ID = "INSTALLATION_ID",
  NOTIFICATIONS = "NOTIFICATIONS",
  NOTIFICATION_CONTACTS = "NOTIFICATION_CONTACTS",
  PLANNING_ID = "PLANNING_ID",
  TITLE = "TITLE",
}
