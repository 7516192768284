import { type ApolloError } from "@apollo/client";
import { Permissions } from "@technis/shared";

import { type ProfileData } from "@services/profileService";

type AlertRights = {
  read: boolean;
  edit: boolean;
  create: boolean;
  delete: boolean;
};

export const getAlertRights = (profileData?: ProfileData, error?: ApolloError): AlertRights => {
  const rights = profileData?.profileById.rights.dashboard.alert;

  if (!profileData) {
    return { [Permissions.READ]: !error, [Permissions.EDIT]: !error, [Permissions.CREATE]: !error, [Permissions.DELETE]: !error };
  }

  if (!rights) {
    return { [Permissions.READ]: false, [Permissions.EDIT]: false, [Permissions.CREATE]: false, [Permissions.DELETE]: false };
  }

  return rights;
};
