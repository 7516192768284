import { type Action } from "@technis/shared";

import { type AlertNotification } from "@common/interfaces/alert";

interface MapNotificationsToActionsArguments {
  isIdSkipped?: boolean;
  notifications: AlertNotification[];
}

interface GetIdArguments {
  id: string | number;
  isIdSkipped: boolean;
}

type NotificationAction = Omit<Action, "id"> & { reminder: number; id: number | undefined };

const getId = ({ id, isIdSkipped }: GetIdArguments): number | undefined => {
  if (isIdSkipped) {
    return;
  }

  if (Number.isNaN(Number(id))) {
    return;
  }

  return Number(id);
};

export const mapNotificationsToActions = ({
  notifications,
  isIdSkipped = false,
}: MapNotificationsToActionsArguments): NotificationAction[] =>
  notifications.map(({ id, type, msgTitle, msgContent }) => ({
    id: getId({ id, isIdSkipped }),
    type,
    msgContent: msgContent,
    msgTitle: msgTitle || " ",
    reminder: 0,
  }));
