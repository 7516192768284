import { MONTH_IN_SECONDS } from "@technis/shared";
import { Cookies, useCookies } from "react-cookie";
import { type CookieSetOptions } from "universal-cookie/cjs/types";

const COOKIE_PREFIX = process.env.LINK_ENV === "production" ? "" : `${process.env.LINK_ENV}-`;
export const COOKIE_AUTH = `${COOKIE_PREFIX}authTechnis`;
export const COOKIE_AUTH_VIEW_AS_USER = `${COOKIE_PREFIX}authViewAsUser`;
export const COOKIE_TRUST_BROWSER = `${COOKIE_PREFIX}trustBrowser`;

const [domain, extension] = window.location.hostname.split(".").splice(-2);
const domainPrefix = extension ? "." : "";
const domainSuffix = extension ? `.${extension}` : "";
export const COOKIE_DOMAIN = `${domainPrefix}${domain}${domainSuffix}`;

type TrustBrowserCookie = {
  setTrustBrowserCookie: (trustBrowser: boolean) => void;
  getTrustBrowserCookie: () => string;
};

export const useTrustBrowserCookie = (): TrustBrowserCookie => {
  const dependency = COOKIE_TRUST_BROWSER;
  const [cookie, setCookie] = useCookies([dependency]);

  const setTrustBrowserCookie = (trustBrowser: boolean): void => {
    setCookie(dependency, trustBrowser, {
      secure: true,
      maxAge: MONTH_IN_SECONDS * 6,
    });
  };

  const getTrustBrowserCookie = (): string => cookie[COOKIE_TRUST_BROWSER];

  return { setTrustBrowserCookie, getTrustBrowserCookie };
};

export const cookies = new Cookies();

export const getCookie = (name: string): string => cookies.get(name);

export const setCookie = (name: string, value: string, options?: CookieSetOptions): void => {
  cookies.set(name, value, options);
};

export const removeCookie = (name: string, options?: CookieSetOptions): void => cookies.remove(name, options);

export const getAuthCookie = (): string => getCookie(COOKIE_AUTH_VIEW_AS_USER) ?? getCookie(COOKIE_AUTH);
