import { EventMetrics } from "@technis/shared";

export const isInstallationsShown = (
  events?: { metrics: string[] }[],
): { isAirQualityShown: null | boolean; isCountingShown: null | boolean } => {
  if (!events) {
    return {
      isCountingShown: null,
      isAirQualityShown: null,
    };
  }

  const isEventCounting = (event: { metrics: string[] }): boolean => event.metrics.includes(EventMetrics.COUNTING);
  const isEventAir = (event: { metrics: string[] }): boolean => event.metrics.includes(EventMetrics.ATMOSPHERE);

  return {
    isCountingShown: events.some(isEventCounting),
    isAirQualityShown: events.some(isEventAir),
  };
};
